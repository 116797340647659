import Prism from 'prismjs';
import React, { createRef, useEffect } from 'react';

function Codeblock(props) {
    var text = props.lang ? Prism.highlight(props.children, Prism.languages[props.lang.toLowerCase()], props.lang) : props.children;
    return React.createElement("pre", { className: "codeblock" },
        props.lang && React.createElement("div", { className: "lang" }, props.lang),
        React.createElement("code", { dangerouslySetInnerHTML: { __html: text } }));
}

function Fade(props) {
    var divRef = createRef();
    useEffect(function () {
        var _a;
        (_a = divRef.current) === null || _a === void 0 ? void 0 : _a.classList[props.fadeIn ? 'add' : 'remove']('fadeIn');
    }, [divRef, props.fadeIn]);
    return React.createElement("div", { ref: divRef, className: "fadeContainer" }, props.children);
}

function Flowbox(props) {
    var _a;
    var _b, _c;
    return React.createElement("div", { className: "flowbox", style: (_a = {},
            _a['--basis'] = "".concat((_b = props.width) !== null && _b !== void 0 ? _b : 20, "em"),
            _a['--margin'] = "".concat((_c = props.margin) !== null && _c !== void 0 ? _c : 0.5, "em"),
            _a) }, props.children);
}

function Navbar(props) {
    return React.createElement("h1", { className: "navbar" },
        props.content,
        props.lightswitch &&
            React.createElement("svg", { onClick: props.onSwitchPull, className: "lightswitch ".concat(props.pulled ? 'pulled' : '') },
                React.createElement("path", { d: "M 10 0 V 40" }),
                React.createElement("path", { d: "M 5 40 h 10 l 2 20 h -14 l 2 -20" })));
}

function Theme(props) {
    var _a;
    var _b;
    useEffect(function () { document.body.className = props.theme === 'dark' ? "lucy theme-".concat(props.theme) : 'lucy'; }, [props.theme]);
    return React.createElement("div", { className: "lucy", style: (_a = {}, _a['--weight'] = (_b = props.weight) !== null && _b !== void 0 ? _b : 300, _a) }, props.children);
}

export { Codeblock, Fade, Flowbox, Navbar, Theme };
